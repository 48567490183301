@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@500;700&display=swap');

.App {
  position: relative;
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  /* background-color: green; */
}

body {
  overflow: hidden;
}

#root {
  overflow:hidden;
}

.viewport-header {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.wrapper {
    position: absolute;
    z-index: 100000;
    text-align: left;
    overflow: hidden;
    /* background-color: red; */
}

h1 {
  color: white;
  position: absolute;
  top: 2vh;
  left: 4vw;
  z-index: 10000;
  font-weight: 700;
  font-size: 5.5vmin;
}

h2 {
  position: abolute;
  color: white;
  z-index: 100000;
  font-size: 5vmin;
  font-weight: 500;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
